<template>
  <div class="memeberdatail_box" style="margin-top: 10px;">
    <el-card>
      <div class="box_main">
        <!-- <div class="main_top_img">
          <div class="main_top_left">
            <span
              v-show="data.combineOrderStatus!='已完成'&&data.combineOrderStatus!=='已关闭'">{{data.combineOrderStatus}}</span>
            <span v-show="data.combineOrderStatus==='已完成'">{{
            data.combineOrderStatus==='已完成'?'待评价':data.combineOrderStatus}}</span>
            <span v-show="data.combineOrderStatus==='已关闭'">{{
            data.combineOrderStatus==='已关闭'?'已完成':data.combineOrderStatus}}</span>
            <div class="dashed"></div>
          </div>
          <div class="main_top_right">

          </div>
        </div> -->
        <div class="esal_tal" style="margin-top: 50px">
          <div class="dids"></div>
          <div class="didses">基本信息</div>
        </div>
        <el-table :data="tableData" border style="margin-left: 40px; margin-top: 10px"
          :header-cell-style="tableHeaderColor" size="medium">
          <el-table-column prop="userName" label="团长姓名" width="">
          </el-table-column>
          <el-table-column prop="brokerData.mobile" label="手机号" width="">
          </el-table-column>
          <el-table-column prop="brokerData.invokeCode" label="邀请码" width="">
          </el-table-column>
        </el-table>
        <div class="esal_tal" style="margin-top: 50px">
          <div class="dids"></div>
          <div class="didses">提现信息</div>
        </div>
        <el-table :data="tableData" border style="margin-left: 40px; margin-top: 10px"
          :header-cell-style="tableHeaderColor" size="medium">
          <el-table-column prop="transferAmountStr" label="提现金额" width="">
          </el-table-column>
          <el-table-column prop="cashoutTime" label="提现时间" width="">
          </el-table-column>
          <el-table-column prop="outBatchNo" label="商家批次" width="">
          </el-table-column>
          <el-table-column prop="outDetailNo" label="商家明细单号" width="">
          </el-table-column>
          <el-table-column prop="transferRemark" label="转账备注" width="">
          </el-table-column>
        </el-table>
      </div>
    </el-card>


  </div>
</template>
<script>
import { cashoutDetail } from '@/api/group'
export default {
  data() {
    return {
      tableData: [],
      data:{}
    };
  },
  created() {
    this.list();
  },
  methods: {
    // table
    tableHeaderColor({
      rowIndex
    }) {
      if (rowIndex === 0) {
        return "background-color: rgba(246, 248, 249, 1);color: #333333;font-weight: 700;";
      }
    },
    //获取详情数据
    list() {
      let id = this.$route.query.id;
      cashoutDetail({
        id
      }).then((res) => {
        this.data = res.data;
        this.tableData.push(res.data)
      });
    },
  },
};
</script>
<style lang="less" scoped>
.el-main{
  background: #fff;
}
/deep/ .el-card{
  height: 100%;
}
.memeberdatail_box {
  margin-left: 0px;
  height: 100%;
}

#red {
  color: red;
}

/deep/ .domain {
  border: 1px solid #efefef;
  width: 100%;
}

.box_main {
  width: 1200px;
  height: 100%;

  .main_top_img {
    display: flex;
    width: 1125px;
    height: 170px;

    .main_top_left {
      width: 285px;
      height: 170px;
      font-size: 30px;
      font-weight: 600;
      text-align: center;
      line-height: 170px;
      color: red;
      position: relative;

      .dashed {
        border-right: 1px dotted #999999;
        width: 1px;
        height: 60%;
        // background-color: red;
        display: inline-block;
        position: absolute;
        top: 45px;
        right: 0px;
      }
    }
  }

  .esal_tal {
    display: flex;

    .dids {
      margin-left: 20px;
      width: 15px;
      height: 15px;
      background-color: rgba(194, 128, 255, 1);
    }

    .didses {
      margin-left: 10px;
      font-size: 16px;
      font-weight: bold;
    }
  }
}
</style>
